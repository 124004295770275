import * as React from "react"
import { useEffect } from "react"
import { Layout } from "../components/layout"
import { Container } from "react-bootstrap"
import { Seo } from "../components/seo"
import { navigate } from "gatsby"

export default function ProductenOpMaat() {
  useEffect(() => {
    navigate("../bedrijven/");
  }, [])

  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <p>Even geduld...</p>
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Producten op maat"
    description="Je zakenrelaties trakteren op lekkere, gezonde producten van lokale teelt? Dat is de mooiste blijk van waardering!"
  />
)
